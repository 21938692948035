import { inject, Injectable } from '@angular/core';
import { MatrixEntityResponseKey, MatrixEntityVisibilityKey } from '@modules/product-matrix/types/matrix';
import { joinUrls } from '@utils/urls';
import { MatrixEntityVisibilityService } from 'src/app/pages/matrix/services/matrix-entity-visibility/matrix-entity-visibility.service';
import { StrategyStateService } from '../strategy-state/strategy-state.service';

@Injectable()
export class StrategyEntityVisibilityService extends MatrixEntityVisibilityService {
  private strategy = inject(StrategyStateService).strategy;

  visivilityKey: MatrixEntityVisibilityKey = 'stores_visibility';

  requestUrl: string = joinUrls('strategies', String(this.strategy()?.id), 'stores');

  responseKey: MatrixEntityResponseKey = 'stores';
}
